<script setup lang="ts">
import BafTable from '@/components/standard/table/baf-table.vue';
import { useLoading } from '@/composeables/use-loading';
import { employeeService } from '@/services/employee/employee-service';
import { models, utils } from 'baf-shared';
import { onBeforeMount, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { TableColumn } from '../standard/table/models/table';
import { useAuth } from '@/composeables/use-auth';
import EmployeeUpdateModal from '@/components/employee/employee-update-modal.vue';

const { t } = useI18n();
const route = useRoute();
const { loading, startLoading, stopLoading } = useLoading();
const { isAdmin } = useAuth();

const columns = [
  TableColumn.for('userActions', '', {
    charactersWidth: 1,
    search: false,
    sort: false,
  }),
  TableColumn.for('fortnoxId', t('general.fortnoxId'), { charactersWidth: 16 }),
  TableColumn.for('email', t('general.email')),
  TableColumn.for('fullName', t('general.name'), {}),
  TableColumn.for('access', t('general.access'), {
    charactersWidth: 35,
    select: true,
    renderer: (_value, row) => {
      const access = (row?.access ?? []) as models.Access[];
      return access.map((access) => t(`general.enums.access.${access}`)).join(', ');
    },
  }),
  TableColumn.for('assignedServiceOrders', t('general.serviceOrders'), {
    charactersWidth: 18,
    right: true,
  }),
  TableColumn.for('assignedServiceOrderRows', t('general.serviceOrderRows'), {
    charactersWidth: 26,
    right: true,
  }),
  TableColumn.for('updated', t('general.updated'), {
    renderer: (value) => utils.date.formatDateTime(value as string),
    right: true,
    charactersWidth: 20,
  }),
];

const data = ref<models.ListEmployee[]>([]);

const loadEmployees = async (forceSync: boolean) => {
  try {
    startLoading();
    data.value = await employeeService.getAll(undefined, forceSync);
  } finally {
    stopLoading();
  }
};

onBeforeMount(async () => {
  await loadEmployees(false);
});

defineExpose({
  forceSyncEmployees: () => loadEmployees(true),
});
</script>

<template>
  <baf-table
    :columns="columns"
    :rows="data"
    :loading="loading"
    :sort-default="{ key: 'fortnoxId' }"
    :search-query="route.query"
    search
    paginate
    hover
  >
    <template #column-userActions="{ row }">
      <div v-if="isAdmin" class="tags" data-testid="tableUserActions">
        <employee-update-modal :employee="row" #="{ open }" @update="loadEmployees(false)">
          <a class="tag is-link is-medium" data-testid="editTag" @click.stop="open">
            <iconify-icon icon="mdi:edit"></iconify-icon>
            <span class="ml-2">{{ $t('general.edit') }}</span>
          </a>
        </employee-update-modal>
      </div>
    </template>
  </baf-table>
</template>
@/services/employee/employee-service
