<script setup lang="ts">
import EmployeeTable from '@/components/employee/employee-table.vue';
import { useAuth } from '@/composeables/use-auth';
import { ref } from 'vue';

const employeeTableRef = ref<InstanceType<typeof EmployeeTable> | null>(null);
const syncLoading = ref(false);
const { isAdmin } = useAuth();

const handleSync = async () => {
  if (!employeeTableRef.value) return;

  try {
    syncLoading.value = true;
    await employeeTableRef.value.forceSyncEmployees();
  } finally {
    syncLoading.value = false;
  }
};
</script>

<template>
  <section class="section">
    <div v-if="isAdmin" class="buttons is-right">
      <button class="button is-success" :class="{ 'is-loading': syncLoading }" @click="handleSync">
        <iconify-icon icon="mdi:sync"></iconify-icon>
        <span class="ml-2">{{ $t('general.syncLatestEmployeeData') }}</span>
      </button>
    </div>
  </section>
  <section class="section">
    <div class="box">
      <employee-table ref="employeeTableRef"></employee-table>
    </div>
  </section>
</template>
