/* prettier-ignore */
export const sv = {
  general: {
    accept: 'Acceptera',
    access: 'Access',
    action: 'Åtgärd',
    actions: 'Åtgärder',
    add: 'Lägg till',
    addition: 'Status',
    additionText: 'Tilläggstext',
    address: 'Adress',
    alarmedArea: 'Larmat utrymme',
    appName: 'Brandab Fusion',
    appNameShort: 'BAF',
    article: 'Artikel',
    articleNumber: 'Artikelnummer',
    asset: 'Objekt',
    assetCreate: 'Skapa objekt',
    assetUpdate: 'Uppdatera objekt',
    assign: 'Tilldela',
    assigned: 'Tilldelad',
    begin: 'Påbörja',
    bottleManufacturer: 'Flasktillverkare',
    bottleManufacturerShort: 'Tillv',
    cancel: 'Avbryt',
    category: 'Kategori',
    categoryValueCreate: 'Skapa kategori värde',
    categoryValueUpdate: 'Uppdatera kategori värde',
    central: 'Central',
    certification: 'Certifikat',
    certificationCity: 'Stad vid underskrift',
    certificationCreate: 'Skapa certifikat',
    certificationRenew: 'Förnya certifikat',
    certificationRowCreate: 'Skapa certifikatrad',
    certificationRows: 'Certifikatrader',
    certificationRowUpdate: 'Uppdatera certifikatrad',
    certifications: 'Certifieringar',
    certificationService: 'Serviceunderlag',
    certificationsExtra: 'Extra certifieringar',
    certificationText: 'Text för framsidan på certet',
    certificationType: 'Certifikattyp',
    certificationUpdate: 'Uppdatera certifikat',
    certificationVerificationCreate: 'Skapa kontrollpunkt',
    certificationVerificationUpdate: 'Uppdatera kontrollpunkt',
    certified: 'Härmed intygas att',
    changeEmployeeInFortnox: 'Anställda som är admin kan endast ändras i fortnox, ta bort "Admin" från befattning på anställd under personallistan',
    checkpoints: 'Kontrollpunkter',
    chooseCertificationType: 'Välj certifikattyp',
    city: 'Stad',
    clearNotes: 'Rensa anteckningar från certifikatrader',
    complete: 'Avsluta',
    completed: 'Klara',
    completeConfirm: 'Är du säker på att du vill avsluta serviceärendet?',
    confirm: 'Bekräfta',
    contact: 'Kontakt',
    contactName: 'Kontakt namn',
    control: 'Kontroll',
    copiedFrom: 'Kopierad från',
    copy: 'Kopiera',
    copyServiceOrdersConfirm:
      'N/A | Är du säker på att du vill kopiera serviceärendet? | Är du säker på att du vill kopiera {count} serviceärende?',
    create: 'Skapa',
    createAndOpen: 'Skapa och öppna',
    created: 'Skapad',
    customer: 'Kund',
    customerDetails: 'Kunduppgifter',
    customerInformation: 'Kundinformation',
    customerNumber: 'Kundnummer',
    customerNumberShort: 'Knr',
    date: 'Datum',
    delete: 'Ta bort',
    deleteConfirm: 'Är du säker på att du vill ta bort "{value}"? Detta kan inte återställas!',
    deleteConfirmPressureTest: 'Är du säker på att du vill ta bort provtryck med serienummer "{value}"? Detta kan inte återställas!',
    deliveryAddress: 'Leveransadress',
    deliveryAddress1: 'Leveransadress 1',
    deliveryAddress2: 'Leveransadress 2',
    deliveryCity: 'Leveransstad',
    deliveryCountry: 'Leveransland',
    deliveryPostCode: 'Leveranspostnummer',
    deliveryInformation: 'Leverans information',
    deny: 'Neka',
    denyReasonText: 'Du måste ange en anledning för att neka serviceärendet',
    description: 'Beskrivning',
    details: 'Detaljer',
    diameter: 'Diameter',
    diameterMm: 'Diameter mm',
    disapprovedServiceOrderRows: 'Ej godkända serviceärende rader',
    done: 'Slutförd',
    edit: 'Redigera',
    email: 'Epost',
    employee: 'Anställd',
    employeeUpdate: 'Uppdatera anställd',
    emptyFilledWeight: 'Tom/Fylld vikt kg',
    emptyWeight: 'Tom vikt kg',
    emptyWeightShort: 'Tom vikt',
    equipmentType: 'Utrustningstyp',
    expirationDate: 'Utgångsdatum ljus',
    extinguishingSystemType: 'Släcksystemtyp',
    filledBy: 'Påfylld av',
    filledPressure: 'Fylld tryck',
    filledWeight: 'Fylld vikt kg',
    filledWeightShort: 'Fylld vikt',
    finish: 'Slutför',
    finished: 'Slutförda',
    fiveYears: '5 år',
    fortnoxId: 'Fortnox ID',
    general: 'Allmänt',
    gas: 'Gas',
    id: 'ID',
    iHaveReadThrough: 'Jag har läst igenom',
    informationEdit: 'Redigera information',
    inspectionCertificate: 'Kontrollintyg',
    inspectionCertificateText: 'Kontroll utförd enligt AFS 2019:1',
    inspector: 'Besiktningsman',
    internalNote: 'Interna anteckningar',
    label: 'Etikett',
    labelCreate: 'Skapa etikett',
    labelEdit: 'Redigera etiketter',
    labels: 'Etiketter',
    labelsUpdate: 'Uppdatera etiketter',
    labelUpdate: 'Uppdatera etikett',
    lastService: 'Senast service',
    legalCompanyName: 'Brandab Skydd & Säkerhet AB',
    liquidSample: 'Vätskeprov',
    loading: 'Laddar',
    loggedInAs: 'Inloggad som',
    loggedOut: 'Utloggad',
    login: 'Logga in',
    logout: 'Logga ut',
    manufacturer: 'Tillverkare',
    manufacturingDate: 'Tillverkningsdatum',
    manufacturingDateShort: 'Tillvdatum',
    manufacturingNumber: 'Tillverkningsnummer',
    manufacturingNumberShort: 'Tillvnr',
    markAsComplete: 'Klarmarkera',
    marking: 'Märkning',
    monthYearPlaceholder: 'mm-yyyy',
    more: 'Mer',
    name: 'Namn',
    next: 'Nästa',
    nextRechargePressureTestExchange: 'Nästa oml / pt / byte',
    nextService: 'Nästa service',
    no: 'Nej',
    noDataFound: 'Ingen data hittades',
    noMatchesFound: 'Inga matchningar hittades',
    note: 'Anteckning',
    notes: 'Anteckningar',
    noValueSelected: 'Inget värde valt',
    object: 'Objekt',
    offlineReady: 'Appen är redo att användas offline',
    ok: 'Ok',
    orderRows: 'Orderrader',
    orders: 'Ordrar',
    organisationNumber: 'Organisationsnummer',
    pageSize: 'Sidstorlek',
    phoneNumber: 'Telefonnummer',
    placement: 'Placering',
    postCode: 'Postnummer',
    pressureTest: 'Provtryck',
    pressureTestCreate: 'Skapa provtryck',
    pressureTestsForSequentialNumber: 'Provtryck för löpnummer {sequentialNumber}',
    pressureTestShort: 'VGG',
    pressureTestShortAlternative: 'PT',
    pressureTestUpdate: 'Uppdatera provtryck',
    pressureTestWithSerialNumber: `med serienummer {serialNumber}`,
    previousEmployee: 'Föregående anställd',
    previousService: 'Föregående service',
    print: 'Skriv ut',
    printReoccurringControl: 'Skriv ut återkommande kontroll',
    printInspectionCertificate: 'Skriv ut kontrollintyg',
    protectedArea: 'Skyddat utrymme',
    reason: 'Anledning',
    reasonText: 'Anledningstext',
    receiptNumber: 'Kvittonummer',
    receiptNumberShort: 'Kvittonr',
    rechargePressureTest: 'OML/PT',
    reload: 'Ladda om',
    reloading: 'Laddar om',
    renew: 'Förnya',
    remark: 'Anmärkning',
    reoccurringControl: 'Återkommande kontroll av gasflaskor',
    reoccurringControlNumber: 'Utskriftsnummer: {number}',
    reoccurringControlText: 'Kontroll utförd enligt AFS 2017:3',
    resetFilter: 'Återställ filter',
    reset: 'Återställ',
    rest: 'Vila',
    restReasonText: 'Du måste ange en anledning för att vila serviceärendet',
    resume: 'Återuppta',
    rowNumber: 'Radnummer',
    rowNumberShort: 'Nr',
    save: 'Spara',
    search: 'Sök',
    serialNumber: 'Serienummer',
    service: 'Service',
    servicedBy: 'Servad av',
    serviceObject: 'Serviceobjekt',
    serviceOrder: 'Serviceärende',
    serviceOrderBasis: 'Serviceunderlag',
    serviceOrderCreate: 'Skapa serviceärende',
    serviceOrderRowCreate: 'Skapa serviceärenderad',
    serviceOrderRows: 'Serviceärenderader',
    serviceOrderRowUpdate: 'Uppdatera serviceärenderad',
    serviceOrders: 'Serviceärende',
    serviceOrderFinish: 'Slutför serviceärende',
    serviceOrderUpdate: 'Uppdatera serviceärende',
    sequentialNumber: 'Löpnummer',
    sequentialNumberCreate: 'Skapa löpnummer',
    signature: 'Signatur',
    size: 'Storlek',
    start: 'Start',
    status: 'Status',
    syncingData: 'Synkar data {count} av {total}',
    syncLatestEmployeeData: 'Synka anställda',
    tenYears: '10 år',
    testedPressureShort: 'PT',
    toDo: 'Att göra',
    type: 'Typ',
    typeOfControl: 'Typ av kontroll',
    update: 'Uppdatera',
    updated: 'Uppdaterad',
    updateLabels: 'Uppdatera etiketter',
    validFrom: 'Giltig från',
    value: 'Värde',
    volumeLiter: 'Volym liter',
    volumeLiterShort: 'Volym',
    warehouse: 'Lager',
    yes: 'Ja',
    enums: {
      access: {
        Certificates: 'Certifikat',
        PressureTests: 'Provtryckningar',
        ServiceOrders: 'Serviceärende',
        Register: 'Register',
        Admin: 'Admin',
      },
      serviceOrderStatus: {
        Created: 'Skapad',
        Assigned: 'Tilldelad',
        Received: 'Mottagen',
        Accepted: 'Accepterad',
        Denied: 'Nekad',
        InProgress: 'Påbörjad',
        Resting: 'Vilande',
        Finished: 'Slutförd',
        Completed: 'Avslutad',
      },
      warehouse: {
        Kalmar: 'Kalmar',
        Karlskrona: 'Karlskrona',
        Oskarshamn: 'Oskarshamn',
        Ryd: 'Ryd',
        Vaxjo: 'Växjö',
      },
      serviceOrderRowStatus: {
        InProgress: 'Påbörjad',
        Finished: 'Slutförd',
      },
      addition: {
        Approved: 'Godkänd',
        Disapproved: 'Ej godkänd',
        Inaccessible: 'Ej åtkomlig',
        NotPicked: 'Ej utvald',
        Unchecked: 'Ej kontrollerad',
      },
      certificationType: {
        FireExtinguisher: 'Brandsläckare',
        ExtinguishingSystem: 'Släcksystem',
        FireAlarm: 'Brandlarm',
        LifeVest: 'Livväst',
        Suit: 'Dräkt',
      },
      certificationVerificationStatus: {
        Approved: 'Godkänd',
        Disapproved: 'Ej godkänd',
      },
      certificationVerificationStatusShort: {
        Approved: 'Ok',
        Disapproved: 'Anm',
      },
      bafErrorSlug: {
        ServiceOrderFinishWithoutRowsCompleted:
          'Alla serviceärenderader måste slutföras innan serviceärendet kan slutföras',
        ServiceOrderDenyWithoutReason: 'Du måste ange en anledning till nekning',
        AssetCannotBeRemovedWhenConnectedToCertification: 'Objekt kan inte tas bort ifall kopplad till certifikat',
        AssetCannotBeRemovedWhenConnectedToPressureTest: 'Objekt kan inte tas bort ifall kopplad till provtryck',
      },
      printTypeQuery: {
        certification: 'Certifikat',
      },
      categoryValueCategory: {
        Manufacturer: 'Tillverkare',
        Gas: 'Gas',
        PressureTestAction: 'Provtrycks Åtgärd',
      },
    },
    routeName: {
      articles: 'Artiklar',
      assets: 'Objekt',
      categoryValues: 'Kategorier och värden',
      certificates: 'Certifikat',
      customers: 'Kunder',
      employees: 'Personal',
      inspectors: 'Besiktningsmän',
      labels: 'Etiketter',
      pressureTests: 'Provtryckningar',
      serviceOrders: 'Serviceärende',
      start: 'Start',
      technicians: 'Tekniker',
    },
  },
  components: {
    theOfflinebar: {
      message:
        'Du verkar vara offline sedan {date} men kan fortsätta jobba som vanligt.',
      changes: `Du har inga ändringar som behöver synkroniseras | Du har en ändring som kommer synkroniseras när du går online igen | Du har {count} ändringar som kommer synkroniseras när du går online igen`,
    },
    printPage: {
      companyAddress: '2 Skyttevägen, Karlskrona, Blekinge, 371 41, Sverige',
      companyName: 'Brandab Skydd & Säkerhet AB',
      logoAlt: 'Brandab Skydd & Säkerhet AB',
      logoTitle: 'Brandab',
    },
    printCertification: {
      body: 'Har kontrollerats och befunnits vara i fullgod kondition.',
    },
  },
  pages: {
    root: {
      welcome:
        'Välkommen till Brandab Fusion! För tillfället stödjer plattformen följande underliggande applikationer:',
      loggedOut: 'Du är utloggad, klicka på knappen nedan för att logga in.',
    },
  },
  languages: {
    text: {
      Swedish: 'Svenska',
      English: 'Engelska',
    },
    i18n: {
      Swedish: 'sv',
      English: 'en',
    }
  },
};
