import { listEmployeeIdbService } from '@/services/indexed-db/employee-idb-service';
import { models, queries } from 'baf-shared';
import { isFailedToFetchErrorOrThrow } from '../standard/failed-to-fetch-service';
import { appHttpService } from '../standard/http-service';

class EmployeeService {
  async getAll(
    query?: queries.GetEmployeesQuery,
    forceSync?: boolean,
  ): Promise<models.ListEmployee[]> {
    try {
      const employees = await appHttpService.request
        .url('/employees')
        .query({
          ...query,
          ...(forceSync !== undefined ? { forceSync } : { forceSync: false }),
        })
        .get()
        .json<models.ListEmployee[]>();
      listEmployeeIdbService.putAll(employees);
      return employees;
    } catch (error) {
      isFailedToFetchErrorOrThrow(error);
      return listEmployeeIdbService.getAll();
    }
  }

  async update(id: string, employee: models.UpdateEmployee) {
    const updatedEmployee = await appHttpService.request
      .url(`/employees/${id}`)
      .put(employee)
      .json<models.ListEmployee>();

    listEmployeeIdbService.put(updatedEmployee);

    return updatedEmployee;
  }
}

export const employeeService = new EmployeeService();
